import {
	userConstants
}from "../constants/user.constants"
import {
	managementConstants
}from "../../containers/Management/store/constants"
import {
	emailConstants
}from "../../containers/Profile/Email/store/constants"
import notice from "../../components/Notice"
import isRu from "../../helpers/isRu"
import i18n from "./localization"
import history from "../../helpers/history"

const initialState = {
	//login;
	isLoading      : false,
	isLoginLoading : false,
	loginError     : null,
	user           : undefined,
	roles          : [],
	userTypeName   : undefined,

	//unblock;
	unblockLoading : false,
	unblockSuccess : null,
	unblockError   : null,

	//confirmPhone;
	confirmPhoneLoading : false,
	confirmPhoneSuccess : null,
	confirmPhoneError   : null,

	//register;
	registerLoading : false,
	registerSuccess : null,
	registerError   : null,

	//getUserTypes
	userTypes         : [],
	getUserTypesError : null,
	userTypesLoading : false,

	//changeProfileData
	isChangedProfileData     : false,
	changeProfileDataError   : null,
	changeProfileDataLoading : false,

	//registerByAdmin;
	registerByAdminLoading : false,
	registerByAdminSuccess : null,
	registerByAdminError   : null,

	//registerByExternalAdmin;
	registerByExternalAdminLoading : false,
	registerByExternalAdminSuccess : null,
	registerByExternalAdminError   : null,

	//getRolesDto
	rolesDto      : [],
	getRolesError : null,

	//IFULLogin
	IFULParameters        : null,
	IFULParametersLoading : false,
	isIFULLoading         : false,
	data                  : null,
	IFULUser              : null,
	isDetachLoading       : false,
	detachIdNumberSuccess : null,

	//IFULBauth
	IFULBauthParameters        : null,
	IFULBauthParametersLoading : false,
	BauthInitData              : null,
	BauthInitLoading           : false,
	BauthProcessData           : null,
	BauthProcessLoading        : false,

	//IFULDG
	DgInitData       : null,
	DgInitLoading    : false,
	DgReadData       : null,
	DgReadLoading    : false,
	DgRequestData    : null,
	DgRequestLoading : false,

	systemEnvironment           : {},
	checkSystemEnvironmentError : null
}

export default function (state = initialState, action){
	//login;
	switch (action.type){
		case emailConstants.ChangeEmail.SUCCESS:{
			return {
				...state,
				user: {
					...state.user,
					email: action.payload.sucParams
				}
			}
		}
		case userConstants.Login.REQUEST:
			//case userConstants.BelpochtaLogin.REQUEST :
			return {
				...state,
				isLoginLoading : true,
				loginError     : null
			}
		case userConstants.Login.SUCCESS:
			//case userConstants.BelpochtaLogin.SUCCESS :
			let user = action.payload.result
			window.localStorage.setItem("user", JSON.stringify({
				userName     : user.userName,
				token        : user.token,
				refreshToken : user.refreshToken,
				sessionId    : user.sessionId
			}))

			// window.location.href = '/account'
			return {
				...state,
				isLoginLoading : false,
				user           : user,
				roles          : user.roles,
				userTypeName   : user?.userType?.name
			}
		case userConstants.Login.FAILURE:
			//case userConstants.BelpochtaLogin.FAILURE :
			return {
				...state,
				isLoginLoading : false,
				loginError     : action.payload.err.data
			}
		case userConstants.ClearLoginData:
			return {
				...state,
				isLoading      : false,
				isLoginLoading : false,
				loginError     : null,
				user           : undefined,
				roles          : [],
				userTypeName   : undefined
			}

		//signLogin;
		case userConstants.SignLogin.REQUEST:
			return {
				...state,
				isLoading : true,
				Error     : null
			}
		case userConstants.SignLogin.SUCCESS:
			user = action.payload.result
			window.localStorage.setItem("user", JSON.stringify({
				userName     : user.userName,
				token        : user.token,
				refreshToken : user.refreshToken,
				sessionId    : user.sessionId
			}))

			//window.location.href = '/account'
			return {
				...state,
				isLoading    : false,
				user         : user,
				roles        : user.roles,
				userTypeName : user?.userType?.name
			}
		case userConstants.SignLogin.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				isLoading : false,
				Error     : action.payload.err.data
			}
		case userConstants.ChangeSign.REQUEST:
			return {
				...state,
				isChangeSign      : false,
				changeSignError   : null,
				changeSignLoading : true
			}
		case userConstants.ChangeSign.SUCCESS:
			notice("success", "ЭЦП успешно прикреплена")
			return {
				...state,
				isChangeSign      : action.payload.result,
				changeSignError   : null,
				changeSignLoading : false
			}
		case userConstants.ChangeSign.FAILURE:
			return {
				...state,
				isChangeSign      : false,
				changeSignError   : action.payload.err,
				changeSignLoading : false
			}

		//refreshUserData;
		case userConstants.RefreshUserData.REQUEST:
			return {
				...state,
				isLoading            : true,
				isChangedProfileData : false
			}
		case userConstants.RefreshUserData.SUCCESS:
			user = action.payload.result
			if (!user)
				user = null

			return {
				...state,
				isLoading    : false,
				user         : user,
				roles        : user ? user.roles : [],
				userTypeName : user && user.userType ? user.userType.name : undefined
			}
		case userConstants.RefreshUserData.FAILURE:
			return {
				...state,
				isLoading : false,
				error     : action.err
			}

		//logout;
		case userConstants.Logout.REQUEST:
			return {
				...state,
				isLoading: true
			}
		case userConstants.Logout.SUCCESS:
			/*  window.location.href = '/'
			   history.replace('/') */
			window.localStorage.removeItem("user")
			return {
				...state,
				isLoading    : false,
				user         : undefined,
				roles        : [],
				userTypeName : undefined,
				data         : action.payload.result
			}
		case userConstants.Logout.FAILURE:
			return {
				...state,
				isLoading    : false,
				user         : null,
				roles        : [],
				userTypeName : undefined,
				error        : action.err
			}

		//unblock;
		case userConstants.Unblock.REQUEST:
			return {
				...state,
				unblockLoading : true,
				unblockSuccess : null,
				unblockError   : null
			}
		case userConstants.Unblock.SUCCESS:
			return {
				...state,
				unblockLoading : false,
				unblockSuccess : action.payload.result,
				unblockError   : null
			}
		case userConstants.Unblock.FAILURE:
			return {
				...state,
				unblockLoading : false,
				unblockSuccess : null,
				unblockError   : action.payload.err
			}

		//confirmPhone;
		case userConstants.ConfirmPhone.REQUEST:
			return {
				...state,
				confirmPhoneLoading : true,
				confirmPhoneSuccess : null,
				confirmPhoneError   : null
			}
		case userConstants.ConfirmPhone.SUCCESS:
			return {
				...state,
				confirmPhoneLoading : false,
				confirmPhoneSuccess : action.payload.result,
				confirmPhoneError   : null
			}
		case userConstants.ConfirmPhone.FAILURE:
			return {
				...state,
				confirmPhoneLoading : false,
				confirmPhoneSuccess : null,
				confirmPhoneError   : action.payload.err
			}

		//register;
		case userConstants.Register.REQUEST:
			return {
				...state,
				registerLoading : true,
				registerSuccess : null,
				registerError   : null
			}
		case userConstants.Register.SUCCESS:
			notice("success", i18n.successRegister[isRu()])
			return {
				...state,
				registerLoading : false,
				registerSuccess : action.payload.result
			}
		case userConstants.Register.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				registerLoading : false,
				registerError   : action.payload.err
			}

		//getUserTypes
		case userConstants.GetUserTypes.REQUEST:
			return {
				...state,
				getUserTypesError: null,
				userTypesLoading: true,
			}
		case userConstants.GetUserTypes.SUCCESS:
			return {
				...state,
				userTypes: action.payload.result,
				userTypesLoading: false,
			}
		case userConstants.GetUserTypes.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				getUserTypesError: action.payload.err,
				userTypesLoading: false,
			}

		//changeProfileData
		case managementConstants.GetUser.SUCCESS:
			return {
				...state,
				isChangedProfileData: false
			}
		case userConstants.ChangeProfileData.REQUEST:
			return {
				...state,
				isChangedProfileData     : false,
				changeProfileDataError   : null,
				changeProfileDataLoading : true
			}
		case userConstants.ChangeProfileData.SUCCESS:
			notice("success", i18n.successChangeData[isRu()])
			return {
				...state,
				isChangedProfileData     : action.payload.result,
				changeProfileDataError   : null,
				changeProfileDataLoading : false
			}
		case userConstants.ChangeProfileData.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				isChangedProfileData     : false,
				changeProfileDataError   : action.payload.err,
				changeProfileDataLoading : false
			}

		//registerByAdmin;
		case userConstants.RegisterByAdmin.CLEAR:
			return {
				...state,
				registerByAdminLoading : false,
				registerByAdminSuccess : null,
				registerByAdminError   : null
			}
		case userConstants.RegisterByAdmin.REQUEST:
			return {
				...state,
				registerByAdminLoading : true,
				registerByAdminSuccess : null,
				registerByAdminError   : null
			}
		case userConstants.RegisterByAdmin.SUCCESS:
			Boolean(action.payload.result) && notice("success", i18n.successRegister[isRu()])
			return {
				...state,
				registerByAdminLoading : false,
				registerByAdminSuccess : action.payload.result
			}
		case userConstants.RegisterByAdmin.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				registerByAdminLoading : false,
				registerByAdminError   : action.payload.err
			}

		//registerByExternalAdmin;
		case userConstants.RegisterByExternalAdmin.REQUEST:
			return {
				...state,
				registerByExternalAdminLoading : true,
				registerByExternalAdminSuccess : null,
				registerByExternalAdminError   : null
			}
		case userConstants.RegisterByExternalAdmin.SUCCESS:
			notice("success", i18n.successRegister[isRu()])
			return {
				...state,
				registerByExternalAdminLoading : false,
				registerByExternalAdminSuccess : action.payload.result
			}
		case userConstants.RegisterByExternalAdmin.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				registerByExternalAdminLoading : false,
				registerByExternalAdminError   : action.payload.err
			}

		//IFULParameters
		case userConstants.GetIFULLoginParameters.REQUEST:
			return {
				...state,
				IFULParameters        : null,
				IFULParametersLoading : true
			}
		case userConstants.GetIFULLoginParameters.SUCCESS:
			return {
				...state,
				IFULParameters        : action.payload.result,
				IFULParametersLoading : true
			}
		case userConstants.GetIFULLoginParameters.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				IFULParametersLoading: false
			}
		case userConstants.GetIFULLoginParameters.CLEAR:
			return {
				...state,
				IFULParameters        : null,
				IFULParametersLoading : false,
				isIFULLoading         : false,
				isLoading             : false,
				IFULUser              : null
			}

		//BauthParameters
		case userConstants.GetIFULBauthParameters.REQUEST:
			return {
				...state,
				IFULBauthParameters        : null,
				IFULBauthParametersLoading : true
			}
		case userConstants.GetIFULBauthParameters.SUCCESS:
			return {
				...state,
				IFULBauthParameters        : action.payload.result,
				IFULBauthParametersLoading : true
			}
		case userConstants.GetIFULBauthParameters.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				IFULBauthParametersLoading: false
			}
		case userConstants.GetIFULBauthParameters.CLEAR:
			return {
				...state,
				IFULBauthParameters        : null,
				IFULBauthParametersLoading : false
			}

		//IDLogin;
		case userConstants.IFULLogin.REQUEST:
			return {
				...state,
				isIFULLoading : false,
				isLoading     : true,
				Error         : null,
				IFULUser      : null
			}
		case userConstants.IFULLogin.SUCCESS:
			user = action.payload.result
			let obj = {}
			let IFULUser = null
			if (user?.token){
				window.localStorage.setItem("user", JSON.stringify({
					userName     : user.userName,
					token        : user.token,
					refreshToken : user.refreshToken,
					sessionId    : user.sessionId
				}))
				window.localStorage.setItem("idToken", user.idToken)
				obj = {
					user         : user,
					roles        : user.roles,
					userTypeName : user.userType?.name
				}
			}
			else {
				IFULUser = {
					firstName            : user.firstName,
					lastName             : user.lastName,
					middleName           : user.middleName,
					identificationNumber : user.identificationNumber
				}
			}
			return {
				...state,
				...obj,
				isIFULLoading : true,
				isLoading     : false,
				IFULUser      : IFULUser
			}
		case userConstants.IFULLogin.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				isIFULLoading : true,
				isLoading     : false,
				Error         : action.payload.err.data,
				IFULUser      : null
			}

		//BelpochtaLogin;
		case userConstants.BelpochtaLogin.REQUEST:
			return {
				...state,
				isBelpochtaLoading : false,
				isLoading          : true,
				Error              : null,
				BelpochtaUser      : null
			}
		case userConstants.BelpochtaLogin.SUCCESS:
			user = action.payload.result
			let ob = {}
			let BelpochtaUser = null

			if (user?.token){
				window.localStorage.setItem("user", JSON.stringify({
					userName     : user.userName,
					token        : user.token,
					refreshToken : user.refreshToken,
					sessionId    : user.sessionId
				}))
				ob = {
					user         : user,
					roles        : user.roles,
					userTypeName : user.userType?.name
				}
			}
			else {
				if (state.user){
					window.localStorage.removeItem("user")
					ob = {
						user         : undefined,
						roles        : [],
						userTypeName : undefined
					}
				}
				BelpochtaUser = {
					firstName          : user.firstName,
					belpochtaAddressId : user.belpochtaAddressId

					/* lastName   : user.lastName,
					   middleName : user.middleName */
				}
			}
			return {
				...state,
				...ob,
				isBelpochtaLoading : true,
				isLoading          : false,
				BelpochtaUser      : BelpochtaUser
			}
		case userConstants.BelpochtaLogin.FAILURE:
			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				isBelpochtaLoading : true,
				isLoading          : false,
				Error              : action.payload.err.data,
				BelpochtaUser      : null
			}

		//BauthInit
		case userConstants.BauthInit.REQUEST:
			return {
				...state,
				BauthInitLoading : true,
				Error            : null,
				BauthInitData    : null
			}
		case userConstants.BauthInit.SUCCESS:
			return {
				...state,
				BauthInitLoading : false,
				BauthInitData    : action.payload.result
			}
		case userConstants.BauthInit.FAILURE:
			window.localStorage.removeItem("IdCardBauth")

			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				BauthInitLoading : false,
				Error            : action.payload.err.data,
				BauthInitData    : null
			}

		//BauthProcess
		case userConstants.BauthProcess.REQUEST:
			return {
				...state,
				BauthProcessLoading : true,
				Error               : null,
				BauthProcessData    : null
			}
		case userConstants.BauthProcess.SUCCESS:
			return {
				...state,
				BauthProcessLoading : false,
				BauthProcessData    : action.payload.result
			}
		case userConstants.BauthProcess.FAILURE:
			window.localStorage.removeItem("IdCardBauth")

			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				BauthProcessLoading : false,
				Error               : action.payload.err.data,
				BauthProcessData    : null
			}

		//DgInit
		case userConstants.DgInit.REQUEST:
			return {
				...state,
				DgInitLoading : true,
				Error         : null,
				DgInitData    : null
			}
		case userConstants.DgInit.SUCCESS:
			return {
				...state,
				DgInitLoading : false,
				DgInitData    : action.payload.result
			}
		case userConstants.DgInit.FAILURE:
			window.localStorage.removeItem("IdCardBauth")

			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				DgInitLoading : false,
				Error         : action.payload.err.data,
				DgInitData    : null
			}

		//DgRead
		case userConstants.DgRead.REQUEST:
			return {
				...state,
				DgReadLoading : true,
				Error         : null,
				DgReadData    : null
			}
		case userConstants.DgRead.SUCCESS:
			return {
				...state,
				DgReadLoading : false,
				DgReadData    : action.payload.result
			}
		case userConstants.DgRead.FAILURE:
			window.localStorage.removeItem("IdCardBauth")

			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				DgReadLoading : false,
				Error         : action.payload.err.data,
				DgReadData    : null
			}

		//DgRequest
		case userConstants.DgRequest.REQUEST:
		case userConstants.AttachIdNumberFromIFUL.REQUEST:
		case userConstants.AttachIdNumberFromProfile.REQUEST:
			return {
				...state,
				DgRequestLoading : true,
				Error            : null,
				DgRequestData    : null
			}
		case userConstants.DgRequest.SUCCESS:
		case userConstants.AttachIdNumberFromIFUL.SUCCESS:
			return {
				...state,
				DgRequestLoading : false,
				DgRequestData    : action.payload.result
			}
		case userConstants.DgRequest.FAILURE:
		case userConstants.AttachIdNumberFromIFUL.FAILURE:
		case userConstants.AttachIdNumberFromProfile.FAILURE:
			window.localStorage.removeItem("IdCardBauth")
			window.localStorage.removeItem("attachIdNumberToExistingUser")

			//notice("error", action.payload.err.data.errors)
			return {
				...state,
				DgRequestLoading : false,
				Error            : action.payload.err.data,
				DgRequestData    : null
			}

		case userConstants.AttachIdNumberFromProfile.SUCCESS:
			return {
				...state,
				DgRequestData    : action.payload.result,
				DgRequestLoading : false,
				user             : {
					...state.user,
					identificationNumber: true
				}
			}

		//getRolesDto
		case userConstants.GetRoles.REQUEST:
			return {
				...state,
				getRolesError : null,
				rolesDto      : []
			}
		case userConstants.GetRoles.SUCCESS:
			return {
				...state,
				rolesDto: action.payload.result
			}
		case userConstants.GetRoles.FAILURE:
			notice("error", action.payload.err.data.errors)
			return {
				...state,
				getRolesError: action.payload.err
			}
		case userConstants.GetRoles.CLEAR:
			return {
				...state,
				getRolesError : null,
				rolesDto      : []
			}

		case userConstants.CheckSystemEnvironment.REQUEST:
			return {
				...state,
				checkSystemEnvironmentError : null,
				systemEnvironment           : {}
			}
		case userConstants.CheckSystemEnvironment.SUCCESS:
			return {
				...state,
				systemEnvironment: action.payload.result
			}
		case userConstants.CheckSystemEnvironment.FAILURE:
			return {
				...state,
				checkSystemEnvironmentError: action.payload.err
			}

		case userConstants.DetachIdNumber.REQUEST:
			return {
				...state,
				isDetachLoading       : true,
				detachIdNumberSuccess : null
			}
		case userConstants.DetachIdNumber.SUCCESS:
			const res = action.payload.result

			return {
				...state,
				detachIdNumberSuccess : res,
				isDetachLoading       : false,
				user                  : {
					...state.user,
					identificationNumber: res ? null : state.user.identificationNumber
				}
			}
		case userConstants.DetachIdNumber.FAILURE:
			return {
				...state,
				isDetachLoading       : false,
				detachIdNumberSuccess : false
			}

		default:
			return state
	}
}
